<template>
  <div>
            <div class="main" v-title data-title="百川资管-AI投研">
    </div>
    <Header class="header" active="ai" />
    <div class="banner">
      <div class="background">
        <b-container>
          <div class="title">宏观、行业大类资产配置模型</div>
          <div class="title">行业轮动模型</div>
          <div class="title">AI财务分析系统</div>
          <div class="title">日常行业和公司分析框架和AI解读</div>
          <div class="title">后台估值清算系统</div>
        </b-container>
      </div>
    </div>
    <div class="content">
      <section class="method">
        <b-container>
          <div class="text">AI投研方法论</div>
          <b-row>
            <div class="col-10">
              <img src="../assets/ai1.png">
            </div>
          </b-row>
        </b-container>
      </section>
      <section class="data">
        <b-container>
          <b-row>
            <div class="text">宏观、行业数据中台</div>
          </b-row>
          <b-row>
            <div class="col-4 left">
              <div class="background">
                <span class="desc">基础数据展示</span>
              </div>
            </div>
            <div class="col-4 center">
              <div class="background">
                <span class="desc">数据计算</span>
              </div>
            </div>
            <div class="col-4 right">
              <div class="background">
                <span class="desc">大类资产配置量化模型</span>
              </div>
            </div>
          </b-row>
        </b-container>
      </section>
      <section class="system">
        <div class="text">自动点评 投研系统化</div>
        <b-container>
          <b-row>
            <div class="item">
              <div class="bg-1">
                <div class="bg-2">
                  <div class="desc">根据财报数据，第一时间自动生成季报、年报的点评报告</div>
                </div>
              </div>
            </div>
            <div class="arrow">
              <img src="../assets/arrow.png">
            </div>
            <div class="item">
              <div class="bg-1">
                <div class="bg-2">
                  <div class="desc">报告将自动发给负责覆盖该股票的研究员，并由研究员撰写主观观点完成报告</div>
                </div>
              </div>
            </div>
            <div class="arrow">
              <img src="../assets/arrow.png">
            </div>
            <div class="item">
              <div class="bg-1">
                <div class="bg-2">
                  <div class="desc">报告生成带有公司样式的word或pdf后，自动发送至公司内部研报库</div>
                </div>
              </div>
            </div>
          </b-row>
        </b-container>
      </section>
      <section class="assistant">
        <div class="text">基金管理智能助手</div>
        <b-container>
          <b-row>
            <div class="line"></div>
            <div class="col-10">
              <div>
                <div class="item">
                  <div>
                    <img src="../assets/ai7.png">
                    <div class="desc">组合管理</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai8.png">
                    <div class="desc">市场解构</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai9.png">
                    <div class="desc">大盘趋势</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai10.png">
                    <div class="desc">行业风格强弱</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="item">
                  <div>
                    <img src="../assets/ai11.png">
                    <div class="desc">行业估值</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai12.png">
                    <div class="desc">因子表现</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai13.png">
                    <div class="desc">行业盈利趋势</div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="../assets/ai14.png">
                    <div class="desc">组合业绩归因</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </b-row>
        </b-container>
      </section>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Ai',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/ai6.jpg");
}
.banner .container {
  position: relative;
  top: 200px;
}
.banner .title {
  font-size: 24px;
  line-height: 1.8;
}
.method {
  padding: 60px 0;
}
.method .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding-bottom: 40px;
}
.method img {
  width: 100%;
}
.method .col-10 {
  margin: 0 auto;
}
.data .left {
  padding-right: 30px;
  height: 240px;
}
.data .center {
  padding-left: 30px;
  padding-right: 30px;
}
.data .right {
  padding-left: 30px;
}
.data .left .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/ai2.png");
  border-radius: 10px;
  text-align: center;
}
.data .center .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/ai3.png");
  border-radius: 10px;
  text-align: center;
}
.data .right .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/ai4.png");
  border-radius: 10px;
  text-align: center;
}
.data .desc {
  padding-bottom: 10px;
  font-size: 20px;
  color: #ffffff;
  border-bottom: solid 2px #ffffff;
  position: relative;
  top: 50%;
}
.data {
  padding-bottom: 50px;
}
.data .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding-bottom: 40px;
}
.system {
  width: 100%;
  height: 500px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/ai5.jpg");
  padding: 50px 0;
}
.system .text {
  width: 100%;
  text-align: center;
  font-size: 28px;
  padding-bottom: 50px;
}
.system .item {
  width: 215px;
  font-size: 18px;
}
.system .bg-1 {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.system .bg-2 {
  padding: 15px;
  background-color: rgba(52, 137, 214, 0.32);
  height: 280px;
}
.system .desc {
  position: relative;
  top: 30%;
}
.arrow {
  padding: 115px 20px 0 20px;
}
.arrow img {
  height: 50px;
}
.assistant {
  padding: 50px 0;
}
.assistant .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding-bottom: 20px;
}
.assistant .col-10 {
  margin: 0 auto;

  padding: 0;
}
.assistant .item {
  width: 25%;
  display: inline-block;
  text-align: center;
  padding: 25px 0 25px 0;
}
.assistant .item .desc {
  padding-top: 10px;
  color: #0b6dcf;
  font-weight: bold;
}
.assistant img {
  width: 136px;
}
.line {
  width: 76%;
  border-top: solid 3px #fff;
  border-image: linear-gradient(to right, #50c6ff, #14388c) 20 20;
  margin: 0 auto;
}
</style>
